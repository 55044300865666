<template>
	<div>
		<div class="d-flex align-items-center justify-content-start flex-wrap">
			<div v-if="text" v-html="renderMd(text)" class="flex-grow-1 mr-2"></div>
			<b-button
				v-if="document.download_link" @click="fireDownloadEvent(document.download_link)"
				:href="document.download_link" target="_blank"
				class="download-button" variant="outline-blue-button"
			>
				Stiahnuť
			</b-button>
		</div>
		<iframe :src="document.link + '#toolbar=0&navpanes=0'" class="mt-3"></iframe>
		<div class="info-callout p-3">
			<img src="../../appzmudri/_layouts/default/img/info-icon.png"/>
			<p class="font-weight-bold">
				Ak vám nejde načítať dokument alebo kvíz, vyskúšajte niektoré z tipov:

				<ul>
					<li>Refresh stránky</li>
					<li>Prekliknutie sa na iné stránky a naspäť</li>
					<li>Odhlásenie/prihlásenie</li>
				</ul>

				V prípade pretrvávajúcich problémov nás kontaktuje na
				<a class="link" href="mailto:team@zmudri.sk">
					team@zmudri.sk
				</a>
			</p>
		</div>
	</div>
</template>

<script>
import MarkdownIt from 'markdown-it'

export default {
	props: {
		document: {
			type: Object,
			required: true
		},
		text: {
			type: String
		},
	},

	data() {
		return {
			md: null
		}
	},

	async created() {
		this.md = new MarkdownIt({
			html: true,
		})
	},

	methods: {
		fireDownloadEvent(worksheetUrl) {
			this.$wEvent.userLog('lesson.worksheet.download', {worksheet_url: worksheetUrl})
		},
		renderMd(input) {
			return this.md.render(input)
		}
	},
}
</script>

<style lang="scss" scoped>
.info-callout {
	font-family: 'Poppins';
	max-width: 467px;
	background: #FFF5D1;
	border: 1px solid #E5D8A7;
	border-radius: 4px;
	margin: 20px auto;
	display: flex;
	align-items: center;

	img {
		margin-right: 30px;
	}

	p {
		color: #998C5C;
		white-space: normal;
		margin: 0;

		a.link {
			font-weight: 700;
			color: inherit;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 480px){
		max-width: 20em;
	}
}
</style>